import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{marginTop: "100 px", paddingTop: "100px"}}>
          Website coming soon...
        </p>      
      </header>
    </div>
  );
}

export default App;
